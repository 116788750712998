/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import amplitudePlugin from "@analytics/amplitude";
import googleAnalytics from "@analytics/google-analytics";
import googleTagManager from "@analytics/google-tag-manager";
import hubSpotPlugin from "@analytics/hubspot";
import Analytics from "analytics";

const plugins = [];

if (process.env.NEXT_PUBLIC_GOOGLE_UNIVERSAL_ANALYTICS_ENABLED === "true")
  plugins.push(
    googleAnalytics({
      trackingId:
        process.env.NEXT_PUBLIC_GOOGLE_UNIVERSAL_ANALYTICS_TRACKING_ID,
    })
  );

if (process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENABLED === "true")
  plugins.push(
    googleTagManager({
      containerId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID,
    })
  );

if (process.env.NEXT_PUBLIC_HUBSPOT_ENABLED === "true")
  plugins.push(
    hubSpotPlugin({
      portalId: process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID,
    })
  );

if (process.env.NEXT_PUBLIC_AMPLITUDE_ENABLED === "true")
  plugins.push(
    amplitudePlugin({
      apiKey: process.env.NEXT_PUBLIC_AMPLITUDE_KEY,
      options: {
        forceHttps: true,
      },
    })
  );

const analytics = Analytics({
  app: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_APP_NAME,
  plugins,
});

export const pageView = () => {
  analytics.page();
};

export const event = (
  {
    action,
    category,
    label,
    value,
  }: { action: string; category?: string; label?: string; value?: string },
  props: any
) => {
  analytics.track(action, {
    category,
    label,
    value,
    ...props,
  });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  event,
  pageView,
};

import axios from "axios";
// import { useIsValidRFC } from "../hooks/useRFCValidation";
import { getCookie } from "../utils/cookies";
// import { growSurfRegister } from "../utils/growsurf";

const JOB_POSTINGS_EN_URL = `https://raw.githubusercontent.com/munditrade/public-content/master/en/JobPostings.json`;
const JOB_POSTINGS_ES_URL = `https://raw.githubusercontent.com/munditrade/public-content/master/es/JobPostings.json`;

const api = process.env.REACT_APP_NEXT_PUBLIC_BACKEND_URL;
const siteURL = process.env.REACT_APP_SITE_URL;

const normalizeRFC = (rfc) => (rfc ? rfc.toUpperCase().trim() : undefined);

export async function registerLead(payload) {
  if (!payload) {
    return false;
  }

  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      crossorigin: "true",
    },
  };

  return await axios.post(
    `${api}/applicant/register-lead`,
    payload,
    requestOptions
  );
}

export async function register(payload) {
  if (
    payload.survey &&
    payload.survey.length > 0 &&
    !payload.survey[0].shouldSendToBackend
  ) {
    return false;
  }

  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      crossorigin: "true",
    },
  };

  return await axios.post(`${api}/applicant/register`, payload, requestOptions);
}

async function publicIp() {
  return new Promise((resolve) => {
    const url = `${siteURL}api/user-ip`;
    try {
      const config = {
        method: "get",
        url,
      };

      axios(config)
        .then((response) => {
          resolve(response.data.ip);
        })
        .catch((error) => {
          console.log(error);
          resolve();
        });
    } catch (err) {
      console.log(err);
      resolve();
    }
  });
}

async function requestHubspot(url, fields) {
  try {
    const ipAddress = await publicIp();
    console.log(`current ip address ${ipAddress}`);
    const data = {
      submittedAt: new Date().getTime().toString(),
      fields,
      context: {
        hutk:
          typeof document !== "undefined"
            ? getCookie("hubspotutk", document.cookie)
            : undefined,
        pageUri:
          typeof window !== "undefined" ? window.location.href : undefined,
        pageName: typeof document !== "undefined" ? document.title : undefined,
        ipAddress,
      },
    };
    const config = {
      method: "post",
      url,
      headers: {
        "Content-Type": "application/json",
        Cookie: "__cfduid=ddf9f6067ce2646fc228da0eb2c1270ca1613438800",
      },
      data: JSON.stringify(data),
    };
    const response = await axios(config);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function registerFormHubspot(payload) {
  if (
    payload.survey &&
    payload.survey.length > 0 &&
    !payload.survey[0].shouldSendToHubspot
  ) {
    return false;
  }

  try {
    const fields = [
      {
        name: "firstname",
        value: payload.firstName,
      },
      {
        name: "lastname",
        value: payload.lastName,
      },
      {
        name: "company",
        value: payload.businessName,
      },
      {
        name: "email",
        value: payload.mail,
      },
      {
        name: "phone",
        value: payload.hubSpotPhone,
      },
      {
        name: "preferredcontactmethod",
        value: payload.contactMethod,
      },
      ...(payload.rfc
        ? [
            {
              name: "rfc",
              value: payload.rfc,
            },
          ]
        : []),
      ...(payload.factoring || payload.fx || payload.insurance
        ? [
            {
              name: "products_of_interest",
              value: [
                ...(payload.factoring ? ["Export"] : []),
                ...(payload.fx ? ["Forex"] : []),
                ...(payload.insurance ? ["Insurance"] : []),
              ].join(";"),
            },
          ]
        : []),
      ...getSurveyFromPayload(payload.survey),
    ];
    await requestHubspot(
      "https://api.hsforms.com/submissions/v3/integration/submit/8714589/3e4311bc-0af7-4bd2-962f-1e4682e44a20",
      fields
    );
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function registerAllyFormHubspot(payload) {
  try {
    const fields = [
      {
        name: "firstname",
        value: payload.firstName,
      },
      {
        name: "lastname",
        value: payload.lastName,
      },
      {
        name: "company",
        value: payload.companyName,
      },
      {
        name: "email",
        value: payload.mail,
      },
      {
        name: "phone",
        value: payload.hubSpotPhone,
      },
      {
        name: "tipo_de_aliado",
        value: payload.typeAlly,
      },
    ];
    const response = await requestHubspot(
      "https://api.hsforms.com/submissions/v3/integration/submit/8714589/1c5c31b8-e768-4f26-b67e-0297116d018b",
      fields
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
}

const getSurveyFromPayload = (survey) => {
  return survey.map((s) => {
    return {
      name: s.questionKey,
      value: Array.isArray(s.answer) ? s.answer.join(";") : s.answer,
    };
  });
};

export async function newApplicant(props) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      crossorigin: "true",
    },
  };
  const response = await axios.post(
    `${api}/career/apply`,
    {
      firstName: props.firstName,
      lastName: props.lastName,
      mail: props.email,
      phone: props.phone,
      linkedIn: props.linkedIn,
      info: props.info,
      position: props.position,
    },
    requestOptions
  );

  if (response.error) return Promise.reject(response.error);
  if (response.data?.error) return Promise.reject(response.data.error);

  return Promise.resolve(response.data);
}

export async function verifyMail({ verificationCode }) {
  console.log(`Verifying mail with code ${verificationCode}`);

  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      crossorigin: "true",
    },
  };
  const response = await axios.post(
    `${api}/applicant/mail/verify`,
    {
      code: verificationCode,
    },
    requestOptions
  );

  if (response.error) return Promise.reject(response.error);
  if (response.data?.error) return Promise.reject(response.data.error);

  return Promise.resolve(response.data);
}

export async function getJobPostingById(id, lang) {
  try {
    const postingURL =
      lang !== "es" ? JOB_POSTINGS_EN_URL : JOB_POSTINGS_ES_URL;
    const response = await axios.get(postingURL);
    const description = await axios.get(response.data[id].contentUrl);
    return {
      job: response.data[id],
      render: description.data,
      fetching: false,
    };
  } catch (e) {
    console.log(e);
  }
}

export async function registerSatCompany({ rfc, ciec, businessName }) {
  console.log(`Registering ciec for Business Name ${businessName}`);

  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      crossorigin: "true",
    },
  };
  const response = await axios.post(
    `${api}/sat/register/company`,
    {
      rfc: normalizeRFC(rfc),
      ciec,
      businessName,
    },
    requestOptions
  );

  if (response.error) return Promise.reject(response.error);
  if (response.data?.error) return Promise.reject(response.data.error);

  return Promise.resolve(response.data);
}

export async function registerSatContact({ rfc, name, email, phone }) {
  console.log(`Registering contact for Business Name ${name}`);

  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      crossorigin: "true",
    },
  };
  const response = await axios.post(
    `${api}/sat/register/contact`,
    {
      rfc: normalizeRFC(rfc),
      name,
      email,
      phone,
    },
    requestOptions
  );

  if (response.error) return Promise.reject(response.error);
  if (response.data?.error) return Promise.reject(response.data.error);

  return Promise.resolve(response.data);
}

export async function getSatStatus({ rfc }) {
  try {
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        crossorigin: "true",
      },
    };
    const response = await axios.post(
      `${api}/sat/validation`,
      {
        rfc: normalizeRFC(rfc),
      },
      requestOptions
    );

    return response;
  } catch (error) {
    return {
      error,
      data: null,
      success: false,
    };
  }
}

export async function validateForm() {
  return true;
}

// export async function validateFormRegister(rfc) {
//   return useIsValidRFC(rfc);
// }

// export async function registerFromGrowSurf(userEmail) {
//   if (!userEmail) {
//     userEmail = store.getState().signup.info.mail;
//   }
//   return growSurfRegister(userEmail);
// }

import React from "react";
import { Helmet } from "react-helmet";

const googleSiteId = process.env.REACT_APP_GOOGLE_SITE_ID;
const googleSiteKey = process.env.REACT_APP_GOOGLE_SITE_KEY;
const metaPixelId = process.env.REACT_APP_META_PIXEL_ID;
const metaPixelTag = process.env.REACT_APP_META_PIXEL_TAG;

export default function TrackingScripts() {
  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${googleSiteId}`}
      ></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${googleSiteId}');`}
      </script>

      <script>
        {`gtag('event', 'conversion', {'send_to': '${googleSiteId}/${googleSiteKey}'});`}
      </script>
      <script id="facebook-pixel">
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${metaPixelId}');
          fbq('track', '${metaPixelTag}');
        `}
      </script>
      <noscript>
        {`<img
            height="1"
            width="1"
            style={{ display: "none" }}
            alt="facebook no script"
            src="https://www.facebook.com/tr?id=203206058013242&ev=PageView&noscript=1"
          />`}
      </noscript>
    </Helmet>
  );
}

import React, { ChangeEvent } from "react";
import Input from "./Input";

const FormField: React.FC<{
  label: string;
  type?: string;
  id: string;
  required?: boolean;
  value: string;
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
}> = ({ label, type, onInputChange, id, required, value, ...props }) => (
  <div className="mt-4 mb-4">
    <label className="text-blue block w-full mb-2 text-sm2">
      {label}
      {required && <span className="text-orange">*</span>}
    </label>
    <Input
      type={type || "text"}
      onChange={onInputChange}
      id={id}
      name={id}
      {...props}
      value={value}
    />
  </div>
);

export default FormField;

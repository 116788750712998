import React from "react";

export default function Input(props: any) {
  return (
    <input
      className="block w-full border rounded px-3 h-10 border-gray text-black text-14"
      {...props}
    />
  );
}

export const validateEmail = (email: string) => {
  const pattern = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  if (pattern.test(email)) return true;

  return false;
};

export const validateRFC = (rfc: string | undefined) => {
  if (!rfc) return false;
  rfc = rfc ? rfc.toUpperCase().trim() : undefined;
  // eslint-disable-next-line max-len
  const re =
    /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
  const validated = rfc && rfc.match(re);

  if (!validated) return false;

  const verificationCharacter = validated.pop();
  const rfcWithoutVerification = validated.slice(1).join("");
  const len = rfcWithoutVerification.length;
  const dictionary = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZÑ";
  const index = len + 1;
  let sum = len === 12 ? 0 : 481;

  for (let i = 0; i < len; i++)
    sum += dictionary.indexOf(rfcWithoutVerification.charAt(i)) * (index - i);

  let expectedCharacter: any = 11 - (sum % 11);
  if (expectedCharacter === 11) expectedCharacter = "0";
  else if (expectedCharacter === 10) expectedCharacter = "A";
  else expectedCharacter = expectedCharacter.toString();

  // Does the verification character match the expected one?
  // or is it a generic RFC (sales to general public)?
  return verificationCharacter !== expectedCharacter &&
    rfcWithoutVerification + verificationCharacter !== "XAXX010101000"
    ? true
    : !(rfcWithoutVerification + verificationCharacter === "XEXX010101000") ||
        true;
};

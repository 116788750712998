export default function getRecaptchaKey(): string | undefined {
  const MUNDI_IO = process.env.REACT_APP_GOOGLE_ENTERPRISE_SITE_ID_PROD;
  const STAGE_MUNDI_WORK =
    process.env.REACT_APP_GOOGLE_ENTERPRISE_SITE_ID_STAGE;
  const RECURSOS_MUNDI_IO =
    process.env.REACT_APP_GOOGLE_ENTERPRISE_SITE_ID_RECURSOS;
  const FACTORAJE_MUNDI_IO =
    process.env.REACT_APP_GOOGLE_ENTERPRISE_SITE_ID_FACTORAJE;

  const hostname = window.location.hostname;

  switch (hostname) {
    case "localhost":
      return STAGE_MUNDI_WORK;
    case "mundi.io":
      return MUNDI_IO;
    case "stage.mundi.work":
      return STAGE_MUNDI_WORK;
    case "recursos.mundi.io":
      return RECURSOS_MUNDI_IO;
    case "factoraje.mundi.io":
      return FACTORAJE_MUNDI_IO;
    default:
      return undefined;
  }
}

export function recatpchaEnabled(): boolean {
  const hostname = window.location.hostname;
  const ALLOWED_DOMAINS =
    process.env.REACT_APP_GOOGLE_RECAPTCHA_ENABLED?.split(",");

  if (ALLOWED_DOMAINS?.includes(hostname)) return true;
  return false;
}

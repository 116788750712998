import React from "react";

const OrangeLoader = () => (
  <div className="loader">
    <style>{`
      .loader {
        display: flex;
        animation: spin 2s linear infinite;
        margin-left: auto;
        margin-right: auto;
      }
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `}</style>
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="loader"
    >
      <path
        d="M2 25C2 37.7025 12.2975 48 25 48C37.7025 48 48 37.7025 48 25C48 12.2975 37.7025 2 25 2"
        stroke="#F05A26"
        strokeWidth="4"
      />
    </svg>
  </div>
);

export default OrangeLoader;

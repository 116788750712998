import React, { ReactNode } from "react";

const CheckboxField: React.FC<{
  label: string;
  required?: boolean;
  children: ReactNode;
}> = ({ label, required, children }) => {
  return (
    <>
      <label className="text-blue block w-full mb-2 text-sm2">
        {label}
        {required && <span className="text-orange">*</span>}
      </label>
      <div className="flex items-center justify-between">{children}</div>
    </>
  );
};

export default CheckboxField;

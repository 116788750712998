import React from "react";

const SuccessOrangeIcon = (props: any) => (
  <svg
    {...props}
    width="124"
    height="124"
    viewBox="0 0 124 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="61.6208"
      cy="61.6208"
      r="59.6208"
      stroke="#F05A26"
      strokeWidth="4"
    />
    <path
      d="M38.146 62.7943L53.0844 79.2265L85.0952 44.0146"
      stroke="#F05A26"
      strokeWidth="4"
    />
  </svg>
);

export default SuccessOrangeIcon;

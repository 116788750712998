import React, { ChangeEvent, useState } from "react";
import Input from "./Input";

const PhoneField: React.FC<{
  label: string;
  type?: string;
  id: string;
  required?: boolean;
  value: string;
  onPhoneChange: (e: string) => void;
}> = ({ label, type, onPhoneChange, id, required, value, ...props }) => {
  const [phoneState, setPhoneState] = useState({
    countryCode: "+52",
    phone: "",
  });

  function handleChange(
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): void {
    const newPhoneState = { ...phoneState, [e.target.id]: e.target.value };
    setPhoneState(newPhoneState);
    const phone =
      newPhoneState.countryCode === "+52" &&
      newPhoneState.phone.charAt(0) !== "1"
        ? `${newPhoneState.countryCode}1${newPhoneState.phone}`
        : `${newPhoneState.countryCode}${newPhoneState.phone}`;

    onPhoneChange(phone);
  }

  return (
    <div className="mt-4 mb-4">
      <label className="text-blue block w-full mb-2 text-sm2">
        {label}
        {required && <span className="text-orange">*</span>}
      </label>
      <div className="flex">
        <select
          id="countryCode"
          className="text-darkGray text-center text-14"
          onChange={handleChange}
          value={phoneState.countryCode}
        >
          <option value="" disabled>
            Cod. País
          </option>
          <option value="+1">+1 - US / Canada</option>
          <option value="+52">+52 - Mexico</option>
        </select>
        <Input
          type="phone"
          onChange={handleChange}
          id={id}
          name={id}
          {...props}
          value={phoneState.phone}
        />
      </div>
    </div>
  );
};

export default PhoneField;

import { ChangeEvent, useState, useEffect, FormEvent, useRef } from "react";
import { ReactComponent as MundiLogo } from "./images/mundi-logo.svg";
import "./App.css";
import CheckboxField from "./components/CheckboxField";
import FormField from "./components/FormField";
import OrangeLoader from "./components/OrangeLoader";
import PhoneField from "./components/PhoneField";
import { validateEmail, validateRFC } from "./utils/validators";

import SuccessOrangeIcon from "./components/SuccessOrangeIcon";
import tracker from "./utils/tracker";
import { registerFormHubspot, registerLead } from "./client/backendHandler";
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import TrackingScripts from "./components/TrackingScripts";
import getRecaptchaKey, { recatpchaEnabled } from "./utils/recaptchaKeys";

const FORM_ACTIVITY = {
  idle: "idle",
  loading: "loading",
  finished: "finished",
  error: "error",
};

const newAuthPortalRegistrationEnabled =
  process.env.NEXT_PUBLIC_AUTH_PORTAL_REGISTRATION_ENABLED === "true";

const IS_RECAPTCHA_ENABLED = recatpchaEnabled();

interface FormProps {
  firstName: string;
  lastName: string;
  mail: string;
  phone: string;
  businessName: string;
  rfc: string;
  factoring: boolean;
  fx: boolean;
  insurance: boolean;
  contactMethod: string;
}

function App() {
  const [formState, setFormState] = useState<FormProps>({
    firstName: "",
    lastName: "",
    mail: "",
    phone: "",
    businessName: "",
    rfc: "",
    factoring: false,
    fx: false,
    insurance: false,
    contactMethod: "",
  });

  const [hasTriggeredValidation, setHasTriggeredValidation] =
    useState<boolean>();
  const [emptyFieldsError, setEmptyFieldsError] = useState(null);
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState<string>();
  const [RFCError, setRFCError] = useState<string>();
  const [reCAPTCHAError, setReCAPTCHAError] = useState<string>();
  const [activity, setActivity] = useState(FORM_ACTIVITY.idle);
  const [validEmail, setValidEmail] = useState<boolean>();
  const [validRFC, setValidRFC] = useState<boolean>();
  const [token, setToken] = useState<string | null>(null);

  const recaptchaRef = useRef<any>();

  const recaptchaKey: string | undefined = getRecaptchaKey();

  useEffect(() => {
    async function getToken() {
      const token = await recaptchaRef?.current?.executeAsync();

      // apply to form data
      setToken(token);
      setReCAPTCHAError("");
    }

    getToken();
  }, []);

  /**
   * to be implemented
   */
  const handleClickRegister = () => {};

  const formatFormState = (formState: FormProps) => {
    const fs = {
      ...formState,
      hubSpotPhone: formState.phone,
      survey: [],
    };

    return fs;
  };

  const validateOneProductIsChecked = () => {
    const factoring: number = +formState.factoring;
    const fx: number = +formState.fx;
    const insurance: number = +formState.insurance;
    return factoring + fx + insurance > 0;
  };

  useEffect(() => {
    if (hasTriggeredValidation) {
      checkFieldsNotEmpty();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, hasTriggeredValidation]);

  const handleSubmit = async (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (!validateOneProductIsChecked())
      setError("Debe elegir al menos un producto");

    setHasTriggeredValidation(true);

    if (checkFieldsNotEmpty() && token) {
      return;
    }

    if (
      validEmail &&
      validRFC &&
      (formState.factoring || formState.fx || formState.insurance)
    ) {
      tracker.event(
        {
          action: "click",
          category: "click_form",
        },
        {
          type: "click",
          name: "click_form",
          source: "landing_mundi",
          resolver: `know_more`,
        }
      );
      setActivity(FORM_ACTIVITY.loading);
      try {
        const formattedState = formatFormState(formState);

        await registerFormHubspot(formattedState);
        await registerLead(formattedState);

        setActivity(FORM_ACTIVITY.finished);
      } catch (err) {
        setActivity(FORM_ACTIVITY.idle);
        setError("Por favor, inténtelo nuevamente");
      }
    }
  };

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.name === "mail") {
      const isEmailValid = validateEmail(e.target.value);
      setValidEmail(isEmailValid);
      if (!isEmailValid)
        setEmailError("Ingrese una dirección de correo electrónico válida");
      else setEmailError("");
    }
    if (e.target.name === "rfc") {
      const isRFCValid = validateRFC(e.target.value);
      setValidRFC(isRFCValid);
      if (!isRFCValid) setRFCError("Por favor ingrese un RFC válido");
      else setRFCError("");
    }

    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  }

  function handleCheckboxChange(e: ChangeEvent<HTMLInputElement>) {
    setFormState({
      ...formState,
      [e.target.name]: e.target.checked,
    });
  }

  const renderEmptyFieldsError = (fieldName: string) => {
    if (emptyFieldsError && fieldName in emptyFieldsError) {
      return (
        <div>
          <p className="text-orange text-13">{"Debe completar este campo"}</p>
        </div>
      );
    }
    return null;
  };

  const checkFieldsNotEmpty = () => {
    setEmptyFieldsError(null);
    const errors: any = {};
    const onlyValidateFields = {
      ...formState,
      factoring: undefined,
      fx: undefined,
      insurance: undefined,
    };

    Object.entries(onlyValidateFields).map(([key, value]) => {
      if (value === "") {
        errors[key] = value;
      }
    });

    setEmptyFieldsError(errors);
    if (!errors) return true;
    return false;
  };

  function renderIdleState(handleSubmit: any) {
    return (
      <form
        autoComplete="off"
        onSubmit={handleSubmit}
        className="flex flex-col center"
        id="mundiSignupAppForm"
      >
        {IS_RECAPTCHA_ENABLED && (
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={recaptchaKey}
          />
        )}
        <MundiLogo />
        <h2 className="my-7">
          Comparte tus datos y recibe una oferta personalizada de factoraje. Te
          contactaremos en menos de 24h.
        </h2>
        <section>
          <FormField
            label="Nombre/s"
            id="firstName"
            onInputChange={handleInputChange}
            required
            value={formState.firstName}
          />
          {renderEmptyFieldsError("firstName")}
          <FormField
            label="Apellido/s"
            id="lastName"
            onInputChange={handleInputChange}
            required
            value={formState.lastName}
          />
          {renderEmptyFieldsError("lastName")}
          <FormField
            label="Correo Electrónico"
            id="mail"
            onInputChange={handleInputChange}
            required
            value={formState.mail}
          />
          {renderEmptyFieldsError("mail")}
          {emailError && (
            <div>
              <p className="text-orange text-13">{emailError}</p>
            </div>
          )}
          <PhoneField
            label="Teléfono"
            id="phone"
            onPhoneChange={(formattedPhone) => {
              setFormState({ ...formState, phone: formattedPhone });
            }}
            required
            value={formState.phone}
          />
          {renderEmptyFieldsError("phone")}
          <FormField
            label="Razón Social"
            id="businessName"
            onInputChange={handleInputChange}
            required
            value={formState.businessName}
          />
          {renderEmptyFieldsError("businessName")}
          <FormField
            label="RFC (Empresarial)"
            id="rfc"
            onInputChange={handleInputChange}
            required
            value={formState.rfc}
          />
          {renderEmptyFieldsError("rfc")}
          {RFCError && (
            <div>
              <p className="text-orange text-13">{RFCError}</p>
            </div>
          )}
          <CheckboxField label="¿Qué te interesa de Mundi?" required>
            <div className="flex flex-col">
              <input
                className="mb-3"
                type="checkbox"
                id="factoring"
                name="factoring"
                checked={formState.factoring}
                onChange={handleCheckboxChange}
              />
              <label className="text-black mx-3 text-center text-13">
                Adelanto de Facturas
              </label>
            </div>
            <div className="flex flex-col">
              <input
                className="mb-3"
                type="checkbox"
                id="fx"
                name="fx"
                checked={formState.fx}
                onChange={handleCheckboxChange}
              />
              <label className="text-black mx-3 text-center text-13">
                Cambio de divisas
              </label>
            </div>
            <div className="flex flex-col">
              <input
                className="mb-3"
                type="checkbox"
                id="insurance"
                name="insurance"
                checked={formState.insurance}
                onChange={handleCheckboxChange}
              />
              <label className="text-black mx-3 text-center text-13">
                Seguros de carga
              </label>
            </div>
          </CheckboxField>
          {error && (
            <div>
              <p className="text-orange text-13">{error}</p>
            </div>
          )}
          {reCAPTCHAError && (
            <div>
              <p className="text-orange text-13">{reCAPTCHAError}</p>
            </div>
          )}
          <div className="w-full mt-8">
            <button
              className="bg-orange disabled:bg-slate-400 text-white w-full rounded h-14 hover:bg-darkOrange"
              onClick={handleClickRegister}
              disabled={!token && IS_RECAPTCHA_ENABLED}
            >
              Registrarme
            </button>
          </div>
        </section>
      </form>
    );
  }

  const renderLoadingState = () => (
    <div className="flex items-center h-510" id="mundi-signup-app-loading">
      <OrangeLoader />
    </div>
  );

  const renderFinishedState = () => (
    <div
      className="flex flex-col justify-around h-510"
      id="mundi-signup-app-finished"
    >
      <SuccessOrangeIcon className="mx-auto" />
      <div className="text-center">
        <h3 className="text-base font-medium text-24">¡Muchas gracias!</h3>
        <h3 className="text-base font-light text-24">
          Te contactaremos en la brevedad
        </h3>
      </div>
      {newAuthPortalRegistrationEnabled && (
        <h2>
          {
            "También podes registrarte en la plataforma ahora y empezar a utilizar nuestro producto de Seguros"
          }
        </h2>
      )}
      {newAuthPortalRegistrationEnabled && (
        <div className="flex w-full items-center justify-center">
          <button
            onClick={() => {
              "handleRegister";
            }}
          >
            {"Quiero registrarme ahora"}
          </button>
        </div>
      )}
      {newAuthPortalRegistrationEnabled && (
        <button
          onClick={() => {
            "handleCancelRegister";
          }}
        >
          {"No quiero registrarme ahora"}
        </button>
      )}
      <TrackingScripts />
    </div>
  );

  return (
    <div id="mundi-signup-app">
      <section className="w-510 px-12 py-16 bg-white rounded-lg drop-shadow-2xl">
        {recaptchaKey ? (
          <>
            {activity === FORM_ACTIVITY.idle && renderIdleState(handleSubmit)}
            {activity === FORM_ACTIVITY.loading && renderLoadingState()}
            {activity === FORM_ACTIVITY.finished && renderFinishedState()}
          </>
        ) : (
          <div>
            La aplicación de Signup no está autorizada para funcionar este
            dominio: {window.location.hostname}
          </div>
        )}
      </section>
    </div>
  );
}

export default App;
